// Analisis.tsx
import React, { useEffect, useRef } from 'react';
import Li, { useSearch } from '../../components/Li';
import { HelmetWrapper } from '../../components'

interface AnalisisProps {
  searchText: string;
  onResults: (resultsExist: boolean) => void;
}

const Analisis: React.FC<AnalisisProps> = ({ searchText, onResults }) => {
  const daftarIsiItems = [
    {
      link: '/Technical-analisis-adalah',
      judul: '1. Technical Analisis Adalah?',
      deskripsi: 'Mengidentifikasi suatu pergerakan harga yang ada di pasar',
    },
    {
      link: '/Tiga-prinsip-dasar-untuk-treder',
      judul: '2. Prinsip Dasar Analisis Teknikal',
      deskripsi: 'ada tiga prinsip bagi trader dan investor untuk membaca pasar dan membuat keputusan trading yang lebih baik.',
    },
    {
      link: '/Apa-itu-Bullish-dan-bearish',
      judul: '3. Apa Itu Bullish dan Bearish?',
      deskripsi: 'Singkatnya Bullish itu naik sedangkan bearish itu sebaliknya',
    },
    {
      link: '/tiga-jenis-trend',
      judul: '4. Tiga jenis trend di technikal analisis',
      deskripsi: 'ada tiga jenis tren yang di pakai oleh trader dan investor untuk membaca pasar',
    },
    {
      link: '/apa-itu-chart',
      judul: '5. apa itu chart dan fungsinya',
      deskripsi: 'charts adalah sebuah gambar atau chart itu fungsi utamanya menunjukan riwayat harga',
    },
    // Add other items as needed
  ];

  const filteredItems = useSearch(daftarIsiItems, searchText, onResults);

  // Ref to store the previous count of filtered items
  const prevResultsExist = useRef<boolean>(filteredItems.length > 0);

  useEffect(() => {
    const currentResultsExist = filteredItems.length > 0;
    // Only call onResults if the existence of results has changed
    if (prevResultsExist.current !== currentResultsExist) {
      onResults(currentResultsExist);
      prevResultsExist.current = currentResultsExist;
    }
  }, [filteredItems, onResults]);

  return (
    <section className="analisis-section">
      <HelmetWrapper
        title="Belajar Technical Analysis dari Nol: Panduan Lengkap untuk Pemula"
        content="Panduan belajar technical analysis dari nol untuk pemula. Pelajari konsep dasar, daftar isi, dan langkah-langkah penting untuk memahami analisis teknikal di dunia trading."
        kycontent="belajar technical analysis, technical analysis pemula, analisis teknikal, panduan trading, belajar trading dari nol, daftar isi technical analysis"
      />

      {filteredItems.length > 0 ? (
        <>
          <h1 className='title'>belajar Technical analisis dari nol</h1>
          <div className="scrollable-content">
            <ul>
              {filteredItems.map((item, index) => (
                <Li
                  key={index}
                  link={item.link}
                  judul={item.judul}
                  deskripsi={item.deskripsi}
                />
              ))}
            </ul>
          </div>
        </>
      ) : null}
    </section>
  );
};

export default Analisis;
