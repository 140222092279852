import React from 'react';
import '../components/style/article.css';
import '../App';
import Analisis from '../page/list/Analisis';

interface BookProps {
  searchText: string;
}

const Page: React.FC<BookProps> = ({ searchText }) => {
  const handleResults = (exists: boolean) => { };



  return (
    <section className="page">
        <Analisis searchText="" onResults={handleResults} />
    </section>
  );
};

export default Page;
