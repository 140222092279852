import React from 'react';
import '../components/style/home.css';
import '../components/style/article.css'
import { Disqus, HelmetWrapper } from '../components'

const About: React.FC = () => {
    const post = {
        title: "",
        slug: "",
    };

    return (
        <section className="article">
            <HelmetWrapper
                title=""
                content=""
                kycontent=""
            /> 
            <div className="article-container">
                <div className='article-text'>
                    <h1>About Us-Bitlab</h1>
                    <p><b>Selamat datang di BitLab.my.id! 🚀</b>
                    <br />Di sini, kamu bisa belajar tentang trading, Web 3, blockchain, dan teknologi masa depan lainnya. Semua informasi kami kemas dengan cara yang simpel, santai, dan tentunya tetap penuh insight buat kamu yang ingin memahami dunia digital dengan lebih baik.</p> <p><b><i>BitLab.my.id</i></b> hadir untuk kamu yang penasaran bagaimana crypto, NFT, atau teknologi baru lainnya bisa mengubah hidup kita. Tanpa ribet, kami menjelaskan semuanya agar lebih mudah dimengerti dan relevan untuk semua kalangan.</p>
                    <p>Kalau kamu punya pertanyaan, ide, atau ingin berdiskusi, jangan ragu untuk menghubungi kami melalui kontak di bawah. Kamu juga bisa berbagi pandangan di kolom komentar. Yuk, kita persiapkan diri bersama untuk menghadapi masa depan digital! 🌐✨</p>

                    <div className="about">
                        <h2>Dikelola oleh</h2>
                        <hr />
                        <div className="info">
                            <div className="info-item">
                                <strong>Nama:</strong> Gilga
                            </div>
                            <div className="info-item">
                                <strong>Instagram:</strong> <a href="https://www.instagram.com/me.gilga/profilecard/?igsh=MTlwajM1c3V5czZ6dQ==" target="_blank" rel="noopener noreferrer">@me.gilga</a>
                            </div>
                            <div className="info-item">
                                <strong>Email:</strong> <a href="mailto:whoamilt7@gmail.com">whoamilt7@gmail.com</a>
                            </div>
                            <div className="info-item">
                                <strong>Peran:</strong> Admin & Content Creator
                            </div>
                            <div className="info-item">
                                <strong>Minat:</strong> Trading, Web 3, Blockchain, Tech
                            </div>
                        </div>
                        <hr />
                        <span>copyright 18/11/2024 by: gilga</span>
                    </div>
                </div>
            </div>
            <div className="article-container full-width">
                <Disqus
                    identifier={post.slug}
                    title={post.title}
                    url={post.slug}
                />
            </div>
        </section>
    );
};

export default About;
