import React from 'react';
import './style/home.css';
import logo from '../assets/img/logo.webp';
import Card from './Card';

import { FaRocket, FaMicrochip } from "react-icons/fa6";

const Home: React.FC = () => {
  return (
    <section className="home">
      <div className="home-container">
        <div className="intro-text">
          <h1>BitLab Dashboard</h1>
          <p>
            Belajar strategi trading untuk pemula, mari kita mulai dari nol sampai bisa. Tidak ada yang tidak mungkin jika kamu mau belajar.
          </p>
          <a href="/page" className="explore-btn">Explore Now</a>
        </div>
        <div className="intro-image">
          <img src={logo} alt="BitLab" style={{ width: '400px', height: 'auto' }} />
        </div>
      </div>

      <div className="update-container">

    
        <div className="update-section">
          <h2 className="update-title">Update <FaMicrochip /></h2>
          <Card
            image="https://assets.bitlab.my.id/image/line-chart.png"
            title="5. Apa Itu Chart dan Fungsinya?"
            description="chart adalah sebuah gambar yang digunakan untuk menunjukkan riwayat atau history dari pergerakan harga, seperti harga saham, crypto, atau aset lainnya."
            link="/apa-itu-chart"
          />
          <Card
            image="https://assets.bitlab.my.id/image/bull-and-bear.jpg"
            title="4. Apa itu Bullish dan bearish?"
            description="Istilah bullish dan bearish sering digunakan dalam dunia investasi untuk menunjukkan arah pergerakan pasar"
            link="/Apa-itu-Bullish-dan-bearish"
          />
          <Card
            image="https://assets.bitlab.my.id/image/Market%20action%20discounts%20everything.jpg"
            title="3. Prinsip Dasar Analisis Teknikal"
            description="Ada 3 prinsip dasar yang harus diketahui untuk menjadi seorang treder."
            link="/Tiga-prinsip-dasar-untuk-treder"
          />
        </div>

        <div className="update-section">
          <h2 className="update-title">Populer <FaRocket /></h2>
          <Card
            image="https://assets.bitlab.my.id/image/bitlab-book.jpg"
            title="Belajar Technical Analisis dari Nol"
            description="Pelajari dasar-dasar analisis teknikal untuk memulai trading dengan percaya diri."
            link="/belajar-Technical-analisis"
          />
        </div>

      </div>
    </section>
  );
};

export default Home;
