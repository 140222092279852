import React, { useState } from 'react';
import { DiscussionEmbed } from 'disqus-react';
import { FaCommentDots, FaDonate } from 'react-icons/fa';
import './style/disqus.css';

interface DisqusModalCommentProps {
  identifier: string;
  title: string;
  url?: string;
  language?: string;
}

const Disqus: React.FC<DisqusModalCommentProps> = ({ identifier, title, url, language = 'en' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const disqusShortname = 'bitlab-my-id'; 
  const disqusConfig = {
    url: `http://bitlab.my.id/${identifier}`,
    identifier,
    title,
    language: "id",
  };

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleDonate = () => {
    window.open("/donate", "_blank");
  };

  return (
    <div>
      <div className="button-container">
        <button className="comment-button" onClick={openModal}>
          <FaCommentDots size={20} className="icon" /> Diskusi
        </button>
        <button className="donate-button" onClick={handleDonate}>
          <FaDonate size={20} className="icon" /> Donate
        </button>
      </div>

      {isOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>
              ×
            </button>
            <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Disqus;
