import React, { useEffect } from 'react';
import '../../components/style/home.css';

interface EkonomiProps {
  darkMode: boolean;
}

const Ekonomi: React.FC<EkonomiProps> = ({ darkMode }) => {
  useEffect(() => {
    const container = document.getElementById('tradingview-widget-container');

    if (container) {
      // Bersihkan kontainer sebelum menambahkan skrip baru
      container.innerHTML = '';

      // Tambahkan delay untuk memastikan DOM siap
      const timeout = setTimeout(() => {
        try {
          const script = document.createElement('script');
          script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-events.js';
          script.async = true;

          const widgetParams = {
            "width": "100%",
            "height": "90%",
            "colorTheme": darkMode ? "dark" : "light",
            "isTransparent": true,
            "locale": "id",
            "importanceFilter": "-1,0,1",
            "countryFilter": "ar,au,br,ca,cn,fr,de,in,id,it,jp,kr,mx,ru,sa,za,tr,gb,us,eu"
          };

          script.innerHTML = JSON.stringify(widgetParams);

          // Penanganan error jika skrip gagal dimuat
          script.onerror = () => {
            console.error("Error loading TradingView script");
            const errorContainer = document.createElement('div');
            errorContainer.textContent = 'Failed to load TradingView widget. Please refresh or try again later.';
            errorContainer.style.color = 'red';
            errorContainer.style.textAlign = 'center';
            errorContainer.style.marginTop = '20px';
            container.appendChild(errorContainer);
          };

          // Tambahkan skrip ke dalam kontainer
          container.appendChild(script);
        } catch (error) {
          console.error("An error occurred while embedding the widget:", error);
        }
      }, 100); // Delay 100ms untuk memastikan DOM ter-render

      // Membersihkan timeout saat komponen dilepas
      return () => {
        clearTimeout(timeout);
        if (container) {
          container.innerHTML = '';
        }
      };
    }
  }, [darkMode]); // Re-render saat `darkMode` berubah

  return (
    <section className="home">
      <div className="tradingview-widget-container" id="tradingview-widget-container">
        <div className="tradingview-widget-container__widget"></div>
        <div className="tradingview-widget-copyright">
          <a href="https://id.tradingview.com/" rel="noopener noreferrer" target="_blank">
            <h4 className="blue-text">Tradingview</h4>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Ekonomi;
