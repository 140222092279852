import * as React from 'react';
import Viewer from 'react-viewer';
import './style/article.css';
import { MdImageSearch } from "react-icons/md";

interface ImageViewerProps {
  src: string;
  alt?: string;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ src, alt = 'Image' }) => {
  const [visible, setVisible] = React.useState(false);
  const [error, setError] = React.useState(false);

  const imglink = 'https://assets.bitlab.my.id/';
  const fallbackImage =
    'https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExOHE3aHlndGdhMDV5cjI3bnh6ZWhzdHRyaGhyenlzd29nczV5a3psNSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3zhxq2ttgN6rEw8SDx/giphy.webp';

  return (
    <>
      <div className="image-container">
        {!error ? (
          <img
            src={imglink + src}
            alt={alt}
            loading="lazy"
            onClick={() => setVisible(true)}
            onError={() => setError(true)}
          />
        ) : (
          <div className="error-container">
            <img
              src={fallbackImage}
              alt="Error"
              className="error-image"
            />
            <br />
            <span className="error-message"><MdImageSearch/>Not Found </span>
          </div>
        )}
      </div>
      <Viewer
        visible={visible}
        onClose={() => setVisible(false)}
        images={[{ src: imglink + src, alt }]}
        zoomable
        rotatable
        scalable
      />
    </>
  );
};

export default ImageViewer;
