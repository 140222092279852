import React from "react";

const NotFound: React.FC = () => {
  return (
    <div className="notfound-container">
      <h1 className="notfound-title">404</h1>
      <p className="notfound-subtitle">Page Not Found</p>
      <p className="notfound-message">
      URL tidak valid. <br />Coba kata kunci lain atau klik tombol Home untuk kembali ke jalanmu
      </p>
      <a href="/" className="notfound-button">
        Return to Home
      </a>
    </div>
  );
};

export default NotFound;
