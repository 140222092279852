import React from 'react';
import '../../components/style/home.css';
import '../../components/style/article.css'
import Analisis from '../list/Analisis';
import { NextCard, Image, Disqus, HelmetWrapper } from '../../components'

import { FaBitcoin } from 'react-icons/fa';
import { RiPageSeparator } from "react-icons/ri";

const Page1: React.FC = () => {
    const handleResults = (exists: boolean) => { };
    const post = {
        title: "page-5",
        slug: "apa-itu-chart",
    };

    return (
        <section className="article">
            <HelmetWrapper
                title="Apa Itu Chart dan Fungsinya?"
                content="Chart adalah representasi visual data harga yang menggambarkan pergerakan harga dalam jangka waktu tertentu. Chart digunakan untuk membantu trader dalam menganalisis tren dan pola pergerakan pasar, yang sangat penting untuk pengambilan keputusan trading."
                kycontent="apa itu chart, fungsi chart, riwayat harga, analisis tren, chart dalam trading, analisis teknikal, jenis chart , line chart, bar chart, candlestick chart"
            />


            <div className="article-container">
                <div className='article-text'>
                    <h1>Apa Itu Chart dan Fungsinya?</h1>
                    <hr />
                    <p>Sederhananya, chart adalah representasi visual yang menunjukkan riwayat atau pergerakan harga dari suatu aset, seperti saham, cryptocurrency, atau aset lainnya.</p>
                    <p>Chart ini penting banget karena jadi alat utama buat menganalisis pasar, khususnya kalau kita mau pakai analisis teknikal. Nah, ada 3 jenis chart yang sering banget dipakai:</p>
                    <div className="article-li">
                        <span>Tiga jenis chart</span>
                        <hr />
                        <ol>
                            <li><b>Line charts</b><br /><i>Ini jenis chart yang paling simpel. Biasanya cuma nunjukin garis yang menghubungkan harga penutupan dari waktu ke waktu.</i></li>
                            <li><b>Bar charts</b><br /><i>Grafik yang menunjukkan lebih banyak informasi, termasuk harga pembukaan, penutupan, tertinggi, dan terendah.</i></li>
                            <li><b>Candlestick Charts</b><br /><i>Grafik populer yang digunakan oleh banyak trader karena memberikan gambaran lengkap tentang pergerakan harga dengan cara visual yang mudah dipahami.</i></li>
                        </ol>
                    </div>
                    <p>Yuk, kita bahas satu per satu biar lebih jelas!</p>
                </div>
            </div>

            <div className="article-container">
                <div className='article-text'>
                    <h3>1. Line Chart</h3>
                    <p><b>Line Chart</b> adalah jenis grafik yang paling sederhana dalam analisis teknikal. Grafik ini hanya menunjukkan garis yang menghubungkan harga penutupan (closing price) dari suatu aset dalam periode waktu tertentu. Karena simpel, line chart sangat cocok digunakan untuk melihat tren pergerakan harga secara umum.</p>
                    <b>Contoh Mudah Dipahami</b>
                    <Image src='image/line-chart.png' alt='line-chart.png' />
                    <p>Bayangkan kamu sedang mengamati harga sebuah aset, misalnya Bitcoin<FaBitcoin />, selama seminggu terakhir:</p>
                    <div className='article-li'>
                        <ul>
                            <li><FaBitcoin />Senin: $35,000</li>
                            <li><FaBitcoin />Selasa: $36,000</li>
                            <li><FaBitcoin />Rabu: $34,500</li>
                            <li><FaBitcoin />Kamis: $37,000</li>
                            <li><FaBitcoin />Jumat: $38,000</li>
                        </ul>
                    </div>
                    <p>Pada line chart, data ini direpresentasikan sebagai titik-titik yang mewakili harga penutupan setiap hari. Titik-titik tersebut kemudian dihubungkan dengan garis untuk membentuk tren harga. Hasilnya, kamu bisa melihat apakah harga Bitcoin selama seminggu itu <b>naik (Bullish)</b> atau <b>turun (Bearish)</b>.</p>
                </div>
            </div>


            <div className="article-container">
                <div className='article-text'>
                    <h3>2. Bar charts</h3>
                    <p><b>Bar charts</b> adalah jenis grafik yang digunakan untuk menggambarkan pergerakan harga dalam analisis teknikal. Dalam satu batang bar chart, terdapat empat harga utama yang mencerminkan pergerakan harga selama periode waktu tertentu: Open (Harga Pembukaan), High (Harga Tertinggi), Low (Harga Terendah), dan Close (Harga Penutupan).</p>
                    <Image src='image/bar-chart.png' alt='bar-chart.png' />

                    <div className='article-li'>
                        <span>Bar charts memiliki 4 harga dalam 1 batang</span>
                        <hr />
                        <ol>
                            <li><b>Open (Harga Pembukaan):</b> <br /> <i>Harga pertama yang tercatat ketika periode waktu dimulai. Misalnya, dalam bar chart harian, harga pembukaan adalah harga pertama yang tercatat pada awal hari tersebut.</i></li>
                            <li><b>High (Harga Tertinggi)</b><br /><i>Harga tertinggi yang tercapai selama periode tersebut. Ini menunjukkan level tertinggi yang dicapai oleh pasar dalam jangka waktu yang dianalisis.</i></li>
                            <li><b>Low (Harga Terendah):</b><br /><i>Harga terendah yang tercatat selama periode tersebut. Ini menunjukkan titik terendah yang tercatat oleh pasar dalam periode yang sama.</i></li>
                            <li><b>Close (Harga Penutupan)</b><br /><i>Harga terakhir yang tercatat pada akhir periode. Harga ini sering dianggap sebagai harga yang paling penting karena sering digunakan untuk menentukan arah pasar selanjutnya.</i></li>
                        </ol>
                    </div>
                    <hr />
                    <b># Bagaimana Bar Chart Terbentuk?</b>

                    <p>Bar chart menggambarkan keempat harga ini dalam satu batang vertikal. Berikut adalah bagaimana masing-masing harga ditampilkan:</p>
                    <div className='article-li'>
                        <ol>
                            <li><b>Rentang Vertikal (High-Low):</b><br /><i>Batang vertikal menunjukkan perbedaan antara harga tertinggi (High) dan harga terendah (Low) selama periode tersebut.</i></li>
                            <li><b>Garis Horizontal Kiri (Open):</b><br /><i>Garis horizontal di sisi kiri batang menunjukkan harga pembukaan (Open) pada awal periode.</i></li>
                            <li><b>Garis Horizontal Kanan (Close):</b><br /><i>Garis horizontal di sisi kanan batang menunjukkan harga penutupan (Close) pada akhir periode.</i></li>
                        </ol>
                    </div>
                    <p>Jika harga penutupan lebih tinggi dari harga pembukaan, batang akan diberi warna hijau atau putih untuk menunjukkan kenaikan harga selama periode tersebut (bullish). Sebaliknya, jika harga penutupan lebih rendah dari harga pembukaan, batang diberi warna merah atau hitam untuk menandakan penurunan harga (bearish).</p>
                    <hr />
                    <b>Contoh Gambar Bar Chart</b>

                    <Image src='image/contoh-bar-chart.jpg' alt='contoh-bar-chart.jpg' />
                    <p>Perhatikan gambar di atas yang diberi nomor 1 hingga 4 untuk memudahkan pemahaman.</p>

                    <b>1. Penutupan dan Pembukaan (Nomor 1):</b>
                    <p><b>Bar chart</b> sebelumnya menunjukkan harga <FaBitcoin />91.000. Setelah itu, pasar membuat <i>Bar Chart</i> baru dengan harga pembukaan <FaBitcoin />91.000, yang sama dengan harga penutupan <i>Bar Chart</i> sebelumnya. Namun, harga pembukaan tidak selalu harus sama dengan harga penutupan sebelumnya, karena kadang terjadi <i>gap</i> ketika pasar dibuka. Gap ini biasanya terjadi di pasar saham, di mana harga pembukaan bisa lebih tinggi atau lebih rendah dibandingkan harga penutupan sebelumnya.</p>

                    <b>2. Harga Terendah (Nomor 2):</b>
                    <p>Setelah itu, harga pembukaan pada <i>Bar Chart</i> nomor 1 turun ke <FaBitcoin />90.000, menciptakan <i>harga terendah</i> pada periode tersebut. Namun, penurunan ini tidak berlanjut dan harga mulai naik kembali menuju <FaBitcoin />95.500.</p>

                    <b>3. Harga Tertinggi (Nomor 3):</b>
                    <p>Harga naik hingga mencapai <FaBitcoin />95.500, yang merupakan <i>harga tertinggi</i> dalam periode tersebut. Setelah itu, harga mulai turun kembali ke <FaBitcoin />94.500.</p>

                    <b>4. Harga Saat Ini dan Harga Penutupan (Nomor 4):</b>
                    <p>Harga saat ini adalah <FaBitcoin />94.500, dan jika pasar membuat <i>Bar Chart</i> baru, maka harga penutupan akan sama dengan harga saat ini (<FaBitcoin />94.500), karena bar chart mencatatkan harga penutupan pada akhir periode.</p>
                    <hr />
                    <h3>Kesimpulan</h3>

                    <p><b>Bar Chart</b> memberikan gambaran visual yang jelas mengenai pergerakan harga dengan memperlihatkan empat harga utama dalam satu batang. Dengan memperhatikan nomor-nomor pada gambar dan tahapan harga (Open, High, Low, Close), Anda bisa lebih mudah memahami pergerakan pasar, pola harga, dan arah tren yang sedang berlangsung. Grafik ini sangat berguna untuk trader dan analis teknikal dalam membuat keputusan trading yang lebih terinformasi.</p>
                </div>
            </div>
            <div className="article-container">
                <div className="article-text">
                    <h3>3. Candlestick Charts</h3>
                    <p>Candlestick chart adalah salah satu jenis grafik yang sangat populer di kalangan trader dan analis teknikal. Grafik ini merepresentasikan empat harga utama dalam satu batang (bar), yaitu:</p>
                    <div className="article-li">
                        <span>Empat elemen utama dalam candlestick:</span>
                        <hr />
                        <ol>
                            <li>Harga pembukaan (<b>Open</b>)</li>
                            <li>Harga tertinggi (<b>High</b>)</li>
                            <li>Harga terendah (<b>Low</b>)</li>
                            <li>Harga penutupan (<b>Close</b>)</li>
                        </ol>
                    </div>
                    <p>
                        Walaupun memiliki kesamaan dengan bar chart, candlestick chart menawarkan keunggulan dalam hal visualisasi. Grafik ini menggunakan warna untuk memberikan informasi langsung tentang arah pergerakan harga, membuat analisis menjadi lebih intuitif.
                    </p>
                    <div className='article-li'>
                        <ul>
                            <li><b>Hijau:</b> Harga penutupan lebih tinggi dari harga pembukaan (kenaikan).</li>
                            <li><b>Merah:</b> Harga penutupan lebih rendah dari harga pembukaan (penurunan).</li>
                        </ul>
                    </div>

                    <Image src="image/candlestick-chart.jpg" alt="candlestick-chart" />
                    <div className="article-li">
                        <span>Struktur candlestick:</span>
                        <hr />
                        <ul>
                            <li>
                                <b>Body (Tubuh)</b> <br />
                                <i>Bagian yang diwarnai (hijau atau merah), mewakili jarak antara harga pembukaan dan harga penutupan. Semakin panjang body, semakin besar perubahan harga selama periode tersebut.</i>
                            </li>
                            <li>
                                <b>Shadow (Ekor)</b> <br />
                                <i>Garis tipis di atas atau di bawah body, yang menunjukkan rentang harga tertinggi dan terendah pada periode tersebut.</i>
                            </li>
                        </ul>
                    </div>
                    <hr />
                    <b>Contoh Gambar Candlestick</b>
                    <Image src="image/contoh-candlestick-chart.jpg" alt="contoh-candlestick-chart" />
                    <p>Perhatikan gambar di atas yang diberi nomor 1 hingga 4 untuk memudahkan pemahaman:</p>

                    <b>1. Penutupan dan Pembukaan (Nomor 1):</b>
                    <p>Harga penutupan candlestick sebelumnya adalah <FaBitcoin />91.000, yang menjadi harga pembukaan candlestick baru. Namun, harga pembukaan tidak selalu sama dengan harga penutupan sebelumnya karena bisa terjadi <i>gap</i> (kesenjangan harga). Gap ini sering ditemukan di pasar saham.</p>

                    <b>2. Harga Terendah (Nomor 2):</b>
                    <p>Setelah pembukaan pada <FaBitcoin />91.000, harga sempat turun hingga <FaBitcoin />90.000, menciptakan harga terendah untuk periode tersebut. Penurunan ini tidak berlanjut, dan harga mulai naik kembali.</p>

                    <b>3. Harga Tertinggi (Nomor 3):</b>
                    <p>Harga kemudian naik hingga mencapai <FaBitcoin />95.500, menjadi harga tertinggi pada periode tersebut. Setelah itu, harga mulai terkoreksi kembali.</p>

                    <b>4. Harga Saat Ini dan Penutupan (Nomor 4):</b>
                    <p>Harga saat ini berada di <FaBitcoin />94.500. Jika periode berakhir, harga ini akan menjadi harga penutupan candlestick.</p>
                    <p>Nah sama Seperti bar chart, candlestick chart memberikan informasi serupa, tetapi lebih intuitif untuk dianalisis.</p>
                    <hr />
                    <b>Kesimpulan</b>
                    <p>
                        Candlestick chart adalah alat penting dalam analisis teknikal karena menyajikan informasi harga secara visual dan mudah dipahami. Dengan struktur <i>body</i> dan <i>shadow</i>, candlestick memungkinkan trader memahami dinamika harga dalam satu periode dengan cepat. Warna hijau dan merah juga membantu mengenali tekanan beli (bullish) atau jual (bearish).
                    </p>
                    <p>
                        Selain itu, candlestick memudahkan identifikasi pola teknikal seperti <i>Doji, Hammer,</i> atau <i>Engulfing</i>, yang sering digunakan untuk memprediksi arah pergerakan harga. Pemahaman yang baik tentang grafik ini akan membantu trader membuat keputusan yang lebih akurat dan strategis.
                    </p>
                </div>
            </div>


            <div className="article-container">
                <div className="article-text">
                    <h3>Penutup</h3>
                    <p>Pada pembahasan selanjutnya, candlestick chart akan menjadi acuan utama karena tampilannya yang intuitif dan kemampuannya menyajikan informasi harga secara lengkap, sehingga sangat cocok untuk analisis teknikal.
                    </p>
                    <h3>Selanjutnya<RiPageSeparator /></h3>
                    <NextCard
                        title=" halaman yang akan datang"
                        description="Parameter waktu pada chart adalah pengaturan yang menentukan rentang waktu yang akan ditampilkan dalam chart."
                        link="#"
                    />
                    <p>Jika Anda menemukan kesalahan dalam penulisan atau memiliki masukan lainnya, jangan ragu untuk menyampaikan di kolom komentar. Masukan Anda sangat berarti untuk meningkatkan kualitas konten ini. Terima kasih sudah membaca!</p>
                </div>

                <div className='list'>
                    <Analisis searchText="" onResults={handleResults} />
                </div>
            </div>

            <div className="article-container full-width">
                <Disqus
                    identifier={post.slug}
                    title={post.title}
                    url={post.slug}
                />
            </div>
        </section>
    );
};

export default Page1;
