import React from 'react';
import { Helmet } from 'react-helmet-async';

interface HelmetWrapperProps {
  title: string;
  content: string;
  kycontent: string;
}

const HelmetWrapper: React.FC<HelmetWrapperProps> = ({ title, content, kycontent }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={content} />
      <meta name="keywords" content={kycontent} />
    </Helmet>
  );
};

export default HelmetWrapper;
