import React from 'react';
import './style/home.css';
import logo from '../assets/img/discord.gif';

const Discord: React.FC = () => {
  return (
    <section className="home">
      <div className="home-container">
        <div className="intro-text">
          <h1>Discord</h1>
          <p>
            Sebuah komunitas kecil yang terdiri dari orang-orang yang suka bermain game
          </p>
          <a href="https://discord.gg/2cD9FWZzK4" className="explore-btn">Join To Discord</a>
        </div>
        <div className="intro-image">
          <img src={logo} alt="BitLab" style={{ width: '400px', height: 'auto' }} />
        </div>

      </div>
    </section>
  );
};

export default Discord;
