import React from 'react';
import './style/card.css';

interface CardProps {
  image: string;
  title: string;
  description: string;
  link: string;
}

const Card: React.FC<CardProps> = ({ image, title, description, link }) => {
  return (
    <a href={link} className="card-link">
      <div className="card">
        <div className="card-image-wrapper">
          <img src={image} alt={title} className="card-image" />
        </div>
        <div className="card-content">
          <h3 className="card-title">{title}</h3>
          <p className="card-description">{description}</p>
        </div>
      </div> 
    </a>
  );
};

export default Card;
