import React from 'react';
import '../../components/style/home.css';
import '../../components/style/article.css'
import Analisis from '../list/Analisis';
import { NextCard, Image, Disqus, HelmetWrapper } from '../../components'

const Page2: React.FC = () => {
    const handleResults = (exists: boolean) => { };
    const post = {
        title: "page-2",
        slug: "Tiga-prinsip-dasar-untuk-treder"
    }

    return (
        <section className="article">
            <HelmetWrapper
                title="3 Prinsip Dasar Analisis Teknikal yang Harus Diketahui Setiap Trader"
                content="Pelajari tiga prinsip dasar analisis teknikal yang membantu trader dalam menganalisis pasar, menentukan tren, support dan resistance, serta mengidentifikasi peluang investasi."
                kycontent="analisis teknikal, prinsip dasar analisis, tren pasar, support dan resistance, indikator teknikal, peluang investasi, trading"
            />

            <div className="article-container">
                <div className='article-text'>
                    <h1>Prinsip Dasar Analisis Teknikal</h1>
                    <hr />
                    <p>Ada 3 prinsip dasar yang harus diketahui untuk menjadi seorang treder.</p>
                    <ol className='article-li'>
                        <li>Market action discounts everything</li>
                        <li>Prices move in trends</li>
                        <li>History repeats itself</li>
                    </ol>
                    <p>Tiga prinsip di atas harus kamu ketahui, mari kita bahas:</p>
                </div>
            </div>

            <div className="article-container">
                <div className='article-text'>
                    <h3>1. Market action discounts everything</h3>
                    <b className='paragraf'>Harga di pasar sudah mencerminkan semua informasi yang ada.</b>
                    <p> Prinsip ini mengatakan bahwa harga suatu aset (seperti saham, mata uang, atau komoditas) sudah mencakup semua informasi yang diketahui tentang aset tersebut. Artinya, berita, laporan, bahkan reaksi emosi dari pelaku pasar terhadap informasi yang ada, semuanya sudah "terhitung" dalam harga saat ini.</p>
                    <b>Contoh Singkat:</b>
                    <Image
                        src="image/Market%20action%20discounts%20everything.jpg"
                        alt="Market action discounts everything"
                    />
                    <p> Ketika ada berita baik tentang perusahaan, harga sahamnya mungkin sudah naik sebelum berita itu secara resmi diumumkan, karena pelaku pasar telah mengantisipasi informasi tersebut. Dengan kata lain, pasar sudah "menghitung" atau memperkirakan informasi itu lebih awal, dan ketika berita akhirnya dirilis, efeknya mungkin tidak terlalu besar atau bahkan tidak ada.</p>
                    <h3>Mengapa Demikian?</h3>
                    <p>Harga saham sering kali bergerak berdasarkan ekspektasi, rumor, atau prediksi sebelum berita benar-benar muncul. Jadi, ketika berita akhirnya diumumkan, banyak pelaku pasar sudah memiliki informasi atau ekspektasi tersebut. Maka, harga bisa saja stagnan atau bahkan turun (karena profit-taking), meskipun beritanya positif.</p>
                    <p>Intinya, harga tidak langsung naik karena berita muncul; sebaliknya, harga sering kali sudah naik lebih dulu karena informasi ini sudah tercermin dalam pergerakan harga sebelumnya.</p>
                </div>
            </div>

            <div className="article-container">
                <div className='article-text'>
                    <h3>2. Prices Move in Trends</h3>
                    <b className='paragraf'>Harga bergerak dalam pola atau tren.</b>
                    <p>Harga di pasar sering bergerak dalam tren, <i>pola naik, turun, atau menyamping</i> selama jangka waktu tertentu. Ketika tren naik, harga cenderung terus naik sebelum akhirnya berbalik turun, dan begitu pula sebaliknya.</p>
                    <b>Contoh Singkat:</b>
                    <Image
                        src="image/Prices%20Move%20in%20Trends.jpg"
                        alt="Prices Move in Trends"
                    />

                    <p>
                        Bayangkan harga minyak mulai naik karena ada gangguan suplai. Kenaikan ini mungkin berlanjut selama beberapa minggu karena permintaan tetap tinggi sementara pasokan terbatas. Trader bisa melihat tren ini dan memutuskan untuk membeli minyak karena tren masih mengarah ke atas. Ketika tren mulai berubah, mereka bisa memutuskan untuk menjual, dan tren akan mulai turun.
                    </p>
                </div>
            </div>

            <div className="article-container">
                <div className='article-text'>
                    <h3>3. History Repeats Itself</h3>
                    <b className='paragraf'>Pola di masa lalu cenderung terulang kembali.</b>
                    <p> Pergerakan harga di pasar cenderung mengikuti pola yang sudah terjadi di masa lalu, karena manusia biasanya bereaksi dengan cara yang sama terhadap situasi tertentu.</p>
                    <b>Contoh Singkat:</b>
                    <Image
                        src='image/History-Repeats-Itself.jpg'
                        alt='History Repeats Itself'
                    />
                    <p>Misalkan setiap kali ada krisis ekonomi besar, harga emas cenderung naik karena orang melihat emas sebagai aset yang aman. Trader yang tahu pola ini akan membeli emas ketika ada tanda-tanda krisis ekonomi karena mereka memprediksi harga emas akan naik, seperti yang pernah terjadi di masa lalu</p>
                    <b>Mengapa Emas Dipilih?</b>
                    <p><b>1. Stabilitas Nilai:</b> Emas cenderung mempertahankan nilainya dalam jangka panjang, meskipun ada gejolak di pasar keuangan atau ekonomi.</p>
                    <p><b>2. Tidak Terpengaruh Inflasi:</b> Emas tidak terpengaruh oleh inflasi atau kebijakan moneter seperti mata uang, yang sering mengalami depresiasi selama krisis ekonomi.</p>
                    <p><b>3. Persepsi Keamanan:</b> Secara psikologis, investor melihat emas sebagai "penyimpan nilai" yang lebih stabil dibandingkan saham atau mata uang selama periode ketidakpastian.</p>
                </div>
            </div>
            <div className="article-container">
                <div className='article-text'>
                    <h3>#. apakah 3 prinsif itu saling berkaitan?</h3>
                    <p>Ya, ketiga prinsip ini saling berkaitan dan membentuk dasar dari analisis teknikal dalam pasar keuangan.</p>
                    <h4>1. Market Action Discounts Everything</h4>
                    <p>Harga sudah mencerminkan semua informasi yang ada. Jadi, trader bisa mengandalkan harga tanpa perlu mencari tahu semua informasi di luar pasar.</p>
                    <h4>2. Prices Move in Trends</h4>
                    <p> Harga cenderung bergerak dalam pola atau tren tertentu (naik, turun, atau menyamping) yang dapat digunakan untuk memprediksi arah selanjutnya</p>
                    <h4>3. History Repeats Itself</h4>
                    <p>Pola harga di masa lalu cenderung berulang karena manusia bereaksi serupa terhadap situasi yang sama. Ini membantu trader memprediksi tren berdasarkan pola sebelumnya.</p>
                    <br />
                </div>

            </div>

            <div className="article-container">
                <div className='article-text'>
                    <h3>#. penutup</h3>
                    <p>Prinsip-prinsip dasar ini membantu kita memahami cara kerja pasar, tetapi keterampilan membaca chart dan menggunakan alat analisis teknikal adalah kunci untuk menjadi trader yang sukses. Ini membutuhkan latihan dan pengalaman untuk bisa mengenali pola dan tren secara efektif, sehingga kita tidak bisa hanya mengandalkan tiga prinsip ini saja.</p>
                    <h3>Selanjutnya: </h3>
                    <NextCard title=" Page 3" description="Mengenal Istilah Bullish Dan Bearish" link="/Apa-itu-Bullish-dan-bearish" />
                    <p>Jika ada kesalahan dalam penulisan atau hal lainnya, mohon koreksi. Jangan sungkan, tulis saja di kolom komentar.</p>
                </div>
                <div className='list'>
                    <Analisis searchText="" onResults={handleResults} />
                </div>
            </div>

            <div className="article-container full-width">
                <Disqus
                    identifier={post.slug}
                    title={post.title}
                    url={post.slug}
                />
            </div>

        </section>
    );
};

export default Page2;
