import React, { useState, useEffect, useCallback } from 'react';
import './components/style/article.css';
import './App.css';
import Analisis from './page/list/Analisis';
// import Indicator from './page/list/Indicator';

interface SearchPageProps {
  searchText: string; 
}

const SearchPage: React.FC<SearchPageProps> = ({ searchText }) => {
  const [results, setResults] = useState<Record<string, boolean>>({
    Analisis: false,
    Indicator: false,
  });

  useEffect(() => {
    // If the searchText changes, reset results
    if (searchText.trim() === '') {
      setResults({ Analisis: false, Indicator: false });
    }
  }, [searchText]);

  // Stabilize handleResults to prevent infinite loop
  const handleResults = useCallback(
    (component: string, resultsExist: boolean) => {
      setResults((prevResults) => {
        // Only update if there is an actual change to avoid re-renders
        if (prevResults[component] !== resultsExist) {
          return { ...prevResults, [component]: resultsExist };
        }
        return prevResults;
      });
    },
    []
  );

  const components = [
    {
      name: 'Analisis',
      component: (
        <Analisis
          searchText={searchText}
          onResults={(exists) => handleResults('Analisis', exists)}
        />
      ),
    },
    // {
    //   name: 'Indicator',
    //   component: (
    //     <Indicator
    //       searchText={searchText}
    //       onResults={(exists) => handleResults('Indicator', exists)}
    //     />
    //   ),
    // },
  ];

  const hasResults = Object.values(results).some((result) => result);

  return (
    <section className="search">
      {searchText.trim() === '' ? (
        <div className="message">
          <h2>Silakan masukkan kata kunci pencarian.</h2>
        </div>
      ) : (
        <>
          {components.map(({ name, component }) => (
            <div key={name} style={{ display: results[name] ? 'block' : 'none' }}>
              {component}
            </div>
          ))}
          {!hasResults && (
            <div className="message">
              <h2>Tidak ada hasil yang ditemukan</h2>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default SearchPage;
