import React from 'react';
import '../../components/style/home.css';
import '../../components/style/article.css'
import Analisis from '../list/Analisis';
import { NextCard, Image, Disqus, HelmetWrapper } from '../../components'

const Page4: React.FC = () => {
    const handleResults = (exists: boolean) => { };
    const post = {
        title: "page-4",
        slug: "tiga-jenis-trend",
    };

    return (
        <section className="article">
            <HelmetWrapper
                title="Tiga Jenis Trend dalam Analisis Teknikal yang Harus Diketahui"
                content="Pelajari tiga jenis tren dalam analisis teknikal yang penting untuk memahami pergerakan pasar: tren naik, tren turun, dan tren sideways. Pahami cara mengidentifikasi dan memanfaatkan masing-masing tren untuk strategi trading yang lebih efektif."
                kycontent="tiga jenis tren, tren naik, tren turun, tren sideways, analisis teknikal, strategi trading, identifikasi tren, pergerakan pasar"
            />

            <div className="article-container">
                <div className='article-text'>
                    <h1>Tiga Jenis Tren dalam Analisis Teknikal</h1>
                    <hr />
                    <Image src='image/tiga-jenis-tren.webp' alt='tiga-jenis-tren' />
                    <p>Jika kamu baru belajar tentang trading, salah satu hal penting yang perlu kamu pahami adalah tren pergerakan harga. Tren ini membantu kamu mengenali arah harga di pasar, sehingga bisa membuat keputusan yang tepat, apakah harus membeli atau menjual. Nah, dalam dunia trading, ada tiga jenis tren utama yang sering terjadi.</p>
                   
                    <div className='article-li'>
                        <span>Tiga Jenis Tren</span>
                        <hr />
                        <ol>
                            <li>Uptrend = Harga naik, pasar optimis (bullish)..</li>
                            <li>Downtrend = Harga turun, pasar pesimis (bearish).</li>
                            <li>Sideways = Harga stagnan, pasar dalam fase konsolidasi atau belum ada kejelasan arah.</li>
                        </ol>
                    </div>

                    <p>Kedua tren pertama, <i>Uptrend</i> dan <i>Downtrend</i>, sudah kita bahas di artikel sebelumnya. Uptrend berarti harga naik (bullish), sedangkan downtrend berarti harga turun (bearish). Namun, mari kita bahas lagi lebih mendalam agar lebih mudah diingat.</p>
                </div>
            </div>

            <div className="article-container">
                <div className='article-text'>
                    <h3>1. Uptrend (Tren Naik)</h3>
                    <Image src='image/uptrend.webp' alt='contoh uptrend' />
                    <p>Tren naik, atau yang disebut uptrend, adalah kondisi ketika harga secara bertahap terus naik. Tren ini sering juga disebut sebagai tren bullish, karena pasar sedang optimis dan banyak orang membeli aset.</p>
                    <div className="article-li">
                        <span>Ciri-ciri Uptrend</span>
                        <hr />
                        <ul>
                            <li>Harga membentuk puncak (high) yang semakin tinggi.</li>
                            <li>Lembah (low) juga ikut naik lebih tinggi.</li>
                            <li>Biasanya terjadi karena ada banyak permintaan (orang ingin membeli).</li>
                        </ul>
                    </div>
                    <b>Contoh:</b>
                    
                    <Image src='image/bullish.png' alt='contoh uptrend' />
                    <p>Misalnya, harga Bitcoin naik dari $51.000 ke $63.000, lalu ke $68.000. Ini menunjukkan bahwa harga semakin tinggi, dan pasar percaya bahwa harga akan terus naik. Jika kamu melihat ini, kamu bisa mempertimbangkan untuk membeli.</p>
                   
                </div>
            </div>

            <div className="article-container">
                <div className='article-text'>
                    <h3>2. Downtrend (Tren Turun)</h3>
                    <Image src='image/downtrend.webp' alt='contoh Downtrend' />
                    <p>Downtrend adalah kondisi ketika harga suatu aset secara konsisten bergerak turun. Tren ini sering disebut sebagai bearish trend, yang menunjukkan bahwa pasar sedang pesimis. Pada fase downtrend, banyak trader atau investor menjual aset mereka karena mereka percaya harga akan terus menurun.</p>
                    <div className="article-li">
                        <span>Ciri-ciri Downtrend</span>
                        <hr />
                        <ul>
                            <li><b>Harga turun secara bertahap:</b>  Harga membentuk puncak (high) dan lembah (low) yang semakin rendah.</li>
                            <li><b>Puncak lebih rendah (lower high):</b> Setiap kali harga naik, titik tertingginya lebih rendah daripada puncak sebelumnya.</li>
                            <li><b>Lembah lebih rendah (lower low):</b> Harga juga membentuk lembah yang terus menurun.</li>
                            <li><b>Banyak penawaran:</b> Pasar dipenuhi oleh penjual yang ingin melepas aset mereka, sehingga tekanan jual meningkat.</li>
                        </ul>
                    </div>
                    <b>Contoh:</b>
                    <Image src='image/bearish.png' alt='contoh Downtrend' />
                    <p>Misalnya, harga Bitcoin (BTC) terus turun dari $60.000 ke $57.000, lalu ke $54.000. Penurunan harga ini menunjukkan bahwa pasar sedang pesimis terhadap aset tersebut. Setiap kali harga mencoba naik, puncaknya tidak pernah melewati harga sebelumnya, yang menjadi indikasi bahwa tren turun masih berlanjut.</p>
                    <div className="article-li">
                        <span>Penjelasan Detail</span>
                        <hr />
                        <ul>
                            <li><b>Lower High (Puncak Lebih Rendah):</b> Harga mencoba naik dari $58.000 ke $59.400, tetapi tidak mencapai puncak sebelumnya di $60.000.</li>
                            <li><b>Lower Low (Lembah Lebih Rendah):</b> Setiap kali harga naik, titik tertingginya lebih rendah daripada puncak sebelumnya.</li>
                            <li><b>Pasar Pesimis:</b> Tekanan jual yang tinggi membuat harga terus tertekan ke bawah.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="article-container">
                <div className='article-text'>
                    <h3>3. Sideways (Tren Mendatar)</h3>
                    <Image src='image/sideways.webp' alt='contoh Sideways' />
                    <p>Sideways adalah kondisi ketika harga tidak naik dan tidak turun secara signifikan, melainkan bergerak dalam kisaran harga yang sempit. Ini biasanya terjadi saat pasar sedang "menunggu" sesuatu, seperti berita besar, data ekonomi, atau keputusan penting dari pelaku pasar. Fase sideways sering disebut sebagai fase konsolidasi sebelum harga menentukan arah berikutnya, apakah akan naik (bullish) atau turun (bearish).</p>
                    <div className="article-li">
                        <span>Ciri-ciri Sideways</span>
                        <hr />
                        <ul>
                            <li><b>Harga stabil:</b> Harga bergerak di area yang hampir sama selama beberapa waktu, tidak membentuk tren naik atau turun yang jelas.</li>
                            <li><b>Tidak ada arah signifikan:</b> Harga bergerak naik-turun dalam kisaran tertentu, tanpa breakout (keluar dari batas atas atau bawah)</li>
                            <li><b>Pasar menunggu sinyal:</b> Sideways sering terjadi ketika pelaku pasar masih menunggu kejelasan, seperti rilis data ekonomi atau sentimen global.
                            </li>
                        </ul>
                    </div>
                    <b>Contoh:</b>
                    <Image src='image/Sideways.png' alt='contoh Sideways' />
                    <p>Misalnya, harga Bitcoin (BTC) bergerak di antara $25.000 hingga $27.000 selama beberapa minggu. Dalam periode ini, meskipun harga sempat naik ke $28.000, namun tidak melanjutkan kenaikan dan kembali turun ke kisaran $25.000 - $27.000. Kondisi ini menunjukkan bahwa pasar belum memiliki arah yang jelas dan sedang dalam fase konsolidasi.</p>
                    <p><i>(Contoh gambar akan menunjukkan grafik harga Bitcoin yang bergerak dalam kisaran sempit antara $25.000 hingga $27.000, dengan garis horizontal sebagai batas support dan resisten.)</i></p>
                </div>
            </div>

            <div className="article-container">
                <div className='article-text'>
                    <h3>#. Kesimpulan</h3>
                    <div className="article-li">
                        <span>Mengetahui jenis tren dalam trading sangat penting.</span>
                        <hr />
                        <ul>
                            <li><b>Uptrend (Bullish):</b> Harga naik, baik untuk membeli.</li>
                            <li><b>Downtrend (Bearish):</b> Harga turun, berhati-hati atau pertimbangkan menjual.</li>
                            <li><b>Sideways:</b> Harga tidak bergerak, lebih baik menunggu sinyal pasar.</li>
                        </ul>
                    </div>
                    <p>Dengan memahami tiga tren ini, kamu akan lebih siap dalam membuat keputusan trading yang tepat dan mengikuti pergerakan pasar dengan lebih percaya diri!</p>
                    <p>akan tetapi memahami tren saja tidak cukup untuk mengambil keputusan, kita harus menganalisa lebih dalam, agar lebih aman :")</p>

                    <h3>Selanjutnya: </h3>
                    <NextCard title="Page 5" description="charts adalah sebuah gambar atau chart itu fungsi utamanya menunjukan riwayat harga" link="/apa-itu-chart" />
                    <p>Jika ada kesalahan dalam penulisan atau hal lainnya, mohon koreksi. Jangan sungkan, tulis saja di kolom komentar.</p>
                </div>
                <div className='list'>
                    <Analisis searchText="" onResults={handleResults} />
                </div>
            </div>


            <div className="article-container full-width">
                <Disqus
                    identifier={post.slug}
                    title={post.title}
                    url={post.slug}
                />
            </div>
        </section>
    );
};

export default Page4;
