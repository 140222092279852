import React, { useState } from 'react';
import { FaBitcoin } from 'react-icons/fa';
import { IoLogoUsd } from "react-icons/io5";
import { SiBnbchain } from "react-icons/si";
import { FaRegFaceSmileBeam } from "react-icons/fa6";
import '../components/style/home.css';
import '../components/style/article.css';
import { HelmetWrapper } from '../components';

const Donate: React.FC = () => {
    const [copied, setCopied] = useState<string | null>(null);
 
    // Alamat dompet untuk berbagai token
    const donateAddresses = {
        USDT: "0xdbae6c6f68059096cdaec74f64619020016255f",
        BTC: "12gsoUAH15XfERL48TBeabqEbhVnvYNPHE",
        BNB: "0xdbae6c6f68059096cdaec74f64619020016255ff",
    };

    // Jaringan yang digunakan oleh masing-masing token
    const networks = {
        USDT: "BEP20 / ERC20",
        BTC: "Bitcoin Network",
        BNB: "BEP20 (Binance Smart Chain)",
    };

    const alternativeLink = "https://sociabuzz.com/me_gilga"; // Tautan alternatif

    // Fungsi untuk menyalin alamat ke clipboard dengan fallback
    const copyToClipboard = (address: string) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(address)
                .then(() => {
                    setCopied(`${address} copied!`);
                    setTimeout(() => setCopied(null), 2000);
                })
                .catch((err) => {
                    console.error("Clipboard write failed", err);
                    fallbackCopy(address);
                });
        } else {
            fallbackCopy(address);
        }
    };

    // Fallback jika clipboard API tidak tersedia
    const fallbackCopy = (text: string) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position = "fixed";
        textArea.style.opacity = "0";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand("copy");
            setCopied(`${text} copied!`);
        } catch (err) {
            console.error("Fallback: Copy command failed", err);
        }
        document.body.removeChild(textArea);
        setTimeout(() => setCopied(null), 2000);
    };

    return (
        <section className="article">
            <HelmetWrapper title="Donate" content="Support us with donations" kycontent="donate token" />

            <div className="article-container">
                <div className="about">
                    <h2>Donate Token</h2>
                    <hr />
                    <div className="donate-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Token</th>
                                    <th>Alamat</th>
                                    <th>Salin</th>
                                    <th>Jaringan</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><IoLogoUsd /> USDT</td>
                                    <td>{donateAddresses.USDT}</td>
                                    <td>
                                        <button onClick={() => copyToClipboard(donateAddresses.USDT)}>
                                            Salin
                                        </button>
                                    </td>
                                    <td>{networks.USDT}</td>
                                </tr>
                                <tr>
                                    <td><FaBitcoin /> BTC</td>
                                    <td>{donateAddresses.BTC}</td>
                                    <td>
                                        <button onClick={() => copyToClipboard(donateAddresses.BTC)}>
                                            Salin
                                        </button>
                                    </td>
                                    <td>{networks.BTC}</td>
                                </tr>
                                <tr>
                                    <td><SiBnbchain /> BNB</td>
                                    <td>{donateAddresses.BNB}</td>
                                    <td>
                                        <button onClick={() => copyToClipboard(donateAddresses.BNB)}>
                                            Salin
                                        </button>
                                    </td>
                                    <td>{networks.BNB}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {copied && <div className="copy-popup">{copied}</div>}
                    <hr />
                    <span>Terimakasih <FaRegFaceSmileBeam /></span>
                </div>
            </div>
            <div className="alternative-button">
                    <button onClick={() => window.open(alternativeLink, "_blank")}>
                        Opsi Lain / E-Wallet
                    </button>
                </div>
        </section>
    );
};

export default Donate;
