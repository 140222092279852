// add
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/img/logo.jpg';

interface SidebarProps {
  toggleDarkMode: () => void;
  darkMode: boolean;
  onSearch: (keyword: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ toggleDarkMode, darkMode, onSearch }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [showText, setShowText] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('BitLab');
    if (query) {
      setSearchText(query);
      onSearch(query);
    }
  }, [location.search, onSearch]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    setShowText(!isOpen);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchText(value);
    onSearch(value);
    if (value.trim() !== '') {
      navigate(`/search?BitLab=${encodeURIComponent(value)}`, { replace: true });
    } else {
      navigate('/page', { replace: true });
    }
  };


  const handleSearchClear = () => {
    setSearchText('');
    onSearch('');
    navigate(location.pathname);
  };

  return (
    <nav className={`sidebar ${isOpen ? '' : 'close'} ${darkMode ? 'dark' : ''}`}>
      <header>
        <div className="image-text">
          <span className="image">
            <img src={logo} alt="Logo" style={{ width: '45px', height: 'auto' }} />
          </span>
          <div className="text logo-text">
            {showText && (
              <>
                <span className="name">BitLab</span>
                <span className="profession">Tutorials & Technology</span>
              </>
            )}
          </div>
        </div>
        <i
          className={`bx ${isOpen ? 'bx-chevron-left' : 'bx-chevron-right'} toggle`}
          onClick={handleToggle}
        ></i>
      </header>
      <div className="menu-bar">
        <div className="menu">
          <li className="search-box">
            <i onClick={handleToggle} className="bx bx-search icon"></i>
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={handleSearchChange}
              ref={searchInputRef}
            />
            {searchText && (
              <i className="bx bx-x icon clear-icon" onClick={handleSearchClear}></i>
            )}
          </li>
          <ul className="menu-links">
            <li className="nav-link">
              <Link to="/" onClick={handleSearchClear}>
                <i className="bx bx-home-alt icon"></i>
                <span className="text nav-text">Home</span>
              </Link>
            </li>
            <li className="nav-link">
              <Link to="/Page" onClick={handleSearchClear}>
                <i className="bx bx-book icon"></i>
                <span className="text nav-text">Page</span>
              </Link>
            </li>
            <li className="nav-link">
              <Link to="/Ekonomi" onClick={handleSearchClear}>
                <i className="bx bx-calendar icon"></i>
                <span className="text nav-text">Kalender Ekonomi</span>
              </Link>
            </li>
          </ul>
        </div>
        <ul className='menu-links'>
          <li className='className="nav-link"'><Link to="/about-me" onClick={handleSearchClear}>
          <i className="bx bx-info-circle icon"></i>


            <span className="text nav-text">Tentang Saya</span>
          </Link>
          </li>
          <li className='className="nav-link"'><Link to="/discord-game" onClick={handleSearchClear}>
          <i className="bx bx-joystick icon"></i>

            <span className="text nav-text">Discord Game</span>
          </Link>
          </li>
        </ul>

        <div className="bottom-content">
          <li className="mode">
            <div className="sun-moon">
              <i className={`bx ${darkMode ? 'bx-sun' : 'bx-moon'} icon`}></i>
            </div>
            <span className="mode-text text">{darkMode ? 'Light mode' : 'Dark mode'}</span>
            <div className="toggle-switch" onClick={toggleDarkMode}>
              <span className="switch"></span>
            </div>
          </li>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
